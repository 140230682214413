elm-accordion, elm-accordion-head {
  display: block;
}

elm-accordion-body {
  height: 0;
  transition: height .15s cubic-bezier(.4, 0, .2, 1);
  display: block;
}
/*# sourceMappingURL=public.a0e715fb.css.map */
